"use client";

import { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { fetchSubTextHero } from "../utils/servers/fetchData";

const TypingText = () => {
  const [sequence, setSequence] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSubTextHero();
      const newSequence = data?.flatMap((item) => [item.typing_text, 1000]);
      setSequence(newSequence);
    };

    fetchData();
  }, []);

  return sequence.length > 0 ? (
    <TypeAnimation
      sequence={sequence}
      wrapper="h3"
      speed={10}
      repeat={Infinity}
    />
  ) : null;
};

export default TypingText;
