"use client"
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { FaShoppingBasket } from "react-icons/fa";
const PromoShow = ({ data }) => {
  const variants = {
    fadeInUp: { opacity: 0, y: 150 },
  };
  return (
    <div className="grid gap-5 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-8">
      {data?.map((elem, index) => (
        <motion.div
          key={index}
          className="promo_card_wrapper text-center relative lg:w-[270]"
          initial={variants.fadeInUp}
          whileInView={{ opacity: 1, x: 0, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <Image
            src={`${process.env.NEXT_PUBLIC_POST_URL}/api/uploads/${elem.product_img}`}
            width={270}
            height={333}
            alt="Card Image"
            priority={false}
            className="m-auto"
          />
          <div className="overlay absolute w-full top-0 h-full flex flex-col items-center justify-center bg-[#141414b3]">
            <p className="mb-0 text-white">Garantia 100% Despacho 48h</p>
            <span className="hover:bg-[#355c7d] p-2 rounded-full">
              <Link
                href={elem.product_link}
                rel="noopener noreferrer"
                target="_blank"
                className="text-white"
                aria-label="Go to shopping basket"
              >
                <FaShoppingBasket className="text-lg text-yellow-600" />
              </Link>
            </span>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default PromoShow;
