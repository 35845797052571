import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/AnimatedMouse.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/CountWrapper.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/FormDetails.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/GroupData.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/Header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/Posts.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/PromoShow.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/ShopData.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/SlideCard.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/puramas/htdocs/puramas.co/app/components/TypingText.js");
;
import(/* webpackMode: "eager" */ "/home/puramas/htdocs/puramas.co/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/puramas/htdocs/puramas.co/node_modules/next/dist/client/image-component.js");
