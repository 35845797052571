"use client";
import { motion } from "framer-motion";
import "intl-tel-input/styles";
import("react-toastify/dist/ReactToastify.css");
import { ToastContainer } from "react-toastify";
import TitleWrapper from "./TitleWrapper";
import Image from "next/image";
import Link from "next/link";
import IconRenderer from "../utils/libs/IconRenderer";
import Form from "./Form";

const FormDetails = ({ data, icon }) => {
  const fadeInVariants = {
    hidden: { opacity: 0, x: -250 },
    visible: { opacity: 1, x: 0 },
  };
  const fadeRightVariants = {
    hidden: { opacity: 0, x: 250 },
    visible: { opacity: 1, x: 0 },
  };
  const renderText = (text) => {
    if (!text) return null;
    return text
      .split(/<br\s*\/?>|\n/)
      .map((line, index) => <p key={index}>{line}</p>);
  };
  return (
    <section id="contacto">
      <div className="container m-auto py-8">
        <TitleWrapper
          title={data.title_text}
          text={data.text_desc}
        />

        <div className="lg:flex gap-10 justify-between my-8">
          <motion.div
            className="text-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={fadeInVariants}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_POST_URL}/api/uploads/${data?.about_img}`}
              alt="Pura+ office image"
              width={500}
              height={268}
              className="mb-4 m-auto"
              priority={false}
            />
            <div className="text-sm text-dark">
              {renderText(data?.desc_about)}
            </div>

            <ul className="flex gap-2 lg:gap-4 mt-4 items-center justify-center">
              {icon?.map((elem) => {
                return (
                  <li
                    key={elem.acid}
                    className="text-xl lg:text-2xl bg-black text-white p-2 lg:p-3 rounded-xl"
                  >
                    <Link
                      href={elem.icon_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`Link to ${elem.icon_name}`}
                    >
                      <IconRenderer iconName={elem.icon_name} />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </motion.div>

          {/* Form Section with Animation */}
          <motion.div
            className="mt-14 lg:mt-0"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={fadeRightVariants}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <Form />
          </motion.div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default FormDetails;
